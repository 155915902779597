.comments-wrapper {
  align-self: flex-start;
  width: 100%;
}

.comments-wrapper .comments-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.comments-wrapper .reply-form {
  margin: 0px;
  padding: 0px;
}

.comments-page {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.comments-page .post {
  padding: 10px;
  width: 485px;

  color: var(--scheme-color-text);
  background-color: var(--scheme-color);
}

.comments-page .post:nth-child(2) {
  margin-top: 0px;
}

.comments-page .post .md-content {
  background-color: transparent;
  color: var(--scheme-color-text);
}

.comments-page .post .md-content a {
  color: var(--theme-color-light);
}

.commentMainWrapper {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
}

.commentHeading {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  padding-bottom: 8px;
}

.commentPostInfo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.commentBtnWrapper {
  flex-grow: 1;
  display: flex;
  /* flex-flow: row nowrap; */
  /* align-items: stretch; */
  justify-content: flex-end;

  margin-left: 8px;
}

@media (max-width: 800px) {
  .comments-wrapper {
    align-self: stretch;
  }

  .comments-page .post {
    align-self: stretch;

    width: auto;
  }
}
