.play .span-panel.join {
  flex-flow: row nowrap;
}

.play .join .left-panel {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
}

.play .join .right-panel {
  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
}

.btn-link {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.join .row .btns-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: 90px;
  height: 28px;
  font-size: 15px;
  box-sizing: border-box; /* 2px padding will be INCLUDED in the WIDTH */
}

.game-row > *:last-child {
  margin-left: auto;
}

.game-row .btn-in-progress {
  opacity: 1;
}

.game-row .btn-review {
  background-color: var(--theme-color-sec);
}

.player-count {
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;

  text-align: center;
}

.join .row .player-circle {
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 50px;

  background-color: var(--theme-color);
  opacity: 0.6;
}

.join .row .player-circle.filled {
  opacity: 1 !important;
}

.join .row .setup-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 450px;
  height: 100%;
}

.join .row .setup-name {
  width: 190px;
  margin-left: 5px;

  overflow-x: hidden;
  white-space: nowrap;
  font-weight: bold;
  opacity: 0.6;
}

.join .row .review-icon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  font-size: 24px;
}

.join .row .game-info,
.join .row .rehost {
  align-self: center;
}

.join .row .game-info,
.join .row .rehost {
  cursor: pointer;
}

.bottom-wrapper {
  flex-grow: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.announcements {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;

  /*width: 350px;*/
  margin-top: 94px;

  background-color: var(--theme-color) !important;
}

.announcements .heading {
  color: white;
}

.announcements .page-nav {
  margin-top: 0px;
  color: white;
}

.announcements .announcement {
  align-self: stretch;

  margin-bottom: 10px;
  padding: 5px;

  background-color: var(--scheme-color);
}

.announcements .announcement .top-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 10px;
}

.announcements .announcement .date {
  margin-left: 10px;

  opacity: 0.6;
}

.announcements .announcement .content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  padding: 5px;

  overflow-x: hidden;
  word-break: break-word;
  white-space: pre-wrap;
}

.announcements .announcement .content a {
  color: var(--theme-color-light);
}

@media (max-width: 800px) {
  .join .row .setup-wrapper {
    width: 135px;
  }

  .join .row .setup-name {
    display: none;
  }

  .bottom-wrapper {
    flex-flow: column-reverse;
  }

  .announcements {
    align-self: stretch;

    margin: 0px;
    margin-top: 10px;

    width: auto;
  }
}
