.nav {
  flex-grow: 1;

  display: flex;
  /* flex-flow: row nowrap; */
  align-items: center;
  justify-content: space-evenly;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nav a {
  padding: 0px 0px 3px 0px;
  border-bottom: 5px solid transparent;

  color: white;
  font-family: var(--primaryFont); /* used to be BNoir */
  /* font-weight: 700; */
  font-size: 26px;
  text-decoration: none;
  opacity: 0.5;
  transition: var(--hoverTransition);

  padding: 12px;
  /*border-radius: 32px;*/
  /*text-decoration: underline rgba(0, 0, 0, 1);*/
  font-weight: bold;
}

.nav a:hover {
  opacity: 1;
  /*background: var(--theme-color-sec);*/
  /*text-decoration-color: white;*/
}

.nav a.active {
  opacity: 1;
}

.nav-wrapper .profile-link {
  border: none;

  opacity: 1;
}

.nav-wrapper .profile-link:hover {
  opacity: 0.7;
}

.nav i {
  margin-left: 5px;

  font-size: 20px;
  cursor: pointer;
}

.nav i:hover {
  opacity: 0.7;
}

.sub-nav {
  display: flex;
  flex-flow: row nowrap;

  border: black;

  background-color: var(--theme-color);
  font-family: var(--primaryFont);
  font-weight: bold;
}

.sub-nav a {
  padding: 5px 10px 5px 10px;

  color: white;
  /* text-decoration: none;
  text-align: center;
  font-weight: 500; */
  transition: var(--hoverTransition);
}

.sub-nav a:hover {
  background: var(--theme-color-sec);
}

.sub-nav a.active {
  background-color: var(--theme-color-sec);
}

.sub-nav .filter {
  flex-grow: 1;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
}

.sub-nav .filter .dropdown {
  display: flex;
  flex-flow: row;

  padding: 0px 10px;

  cursor: pointer;
}

.sub-nav .filter .dropdown-control {
  opacity: 0.5;
}

/* .sub-nav .dropdown {
	display: none;
	flex-flow: column;

	position: absolute;
	top: 33px;
	z-index: 5;

	background-color: #1d1d1d;
	box-shadow: 0px 1px 3px black;
	white-space: nowrap;
} */

/* .sub-nav .dropdown-container:hover .dropdown {
	display: flex;
}

.sub-nav .dropdown a {
	border-bottom: 0px !important;
}

.sub-nav .dropdown a:hover {
	background-color: #242424;
}

.sub-nav .dropdown a.active {
	background-color: #242424;
} */

.btn-group {
  display: flex;
  flex-flow: row nowrap;

  box-shadow: 0px 0px 1px black;
}

.btn-group .btn {
  padding: 8px 10px;
  border: none;
  border-radius: 0px;

  background-color: #2e2e2e;
  box-shadow: none;
  cursor: pointer;
}

.btn-group .btn:first-child {
  border-radius: 3px 0px 0px 3px;
}

.btn-group .btn:last-child {
  border-radius: 0px 3px 3px 0px;
}

.btn-group .btn:hover {
  opacity: 0.75;
}

.btn-group .btn-sel {
  background-color: #373737 !important;
}

.page-nav {
  display: flex;
  flex-flow: row nowrap;

  /*border: 1px solid var(--theme-color-sec);*/
  -webkit-user-select: none;
  user-select: none;
}

.page-nav .page-nav-left,
.page-nav .page-nav-right,
.page-nav .page-num {
  padding: 5px 10px;

  background-color: var(--theme-color-sec);
  cursor: pointer;
}

.page-nav .page-nav-left:hover,
.page-nav .page-nav-right:hover,
.page-nav .page-num:hover {
  opacity: 0.8;
}

.page-nav .page-num.page-sel {
  background-color: var(--theme-color) !important;
}

.page-nav.inverted {
  /* border: 1px solid var(--theme-color); */
}

.page-nav.inverted .page-nav-left,
.page-nav.inverted .page-nav-right,
.page-nav.inverted .page-num {
  padding: 5px 10px;

  background-color: var(--theme-color);
  cursor: pointer;
}

.page-nav.inverted .page-num.page-sel {
  background-color: var(--theme-color-sec) !important;
}

@media (max-width: 800px) {
  .sub-nav {
    font-size: 15px;
  }

  .sub-nav a {
    padding: 3px 5px;
  }

  .sub-nav .filter {
    justify-content: center;
  }

  .sub-nav .filter .dropdown {
    align-items: flex-start;

    padding: 0px;
  }

  .sub-nav .filter .dropdown-control {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    font-size: 11px;
  }
}
