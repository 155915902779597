.setup {
  align-self: flex-start;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-wrap: nowrap;

  height: 100%;
  max-width: 100%;
  border-radius: 3px;
  overflow: hidden;

  color: var(--scheme-color-text);
  cursor: pointer;
}

.setup .fa-ellipsis-h {
  align-self: flex-end;
  margin-left: 5px;
}

.setup .multi-setup-icon {
  font-size: 20px;
  margin-right: 5px;
}

.popover .setup,
.roleList {
  flex-flow: row wrap;
}

.setup .fa-question {
  font-size: 30px !important;
  margin-right: 5px;
  text-shadow: 0 0.04rem 0rem black;
}

.setup::-webkit-scrollbar {
  height: 5px;
}

.setup::-webkit-scrollbar-track {
  background: #1d1d1d;
  border-radius: 3px;
}

.setup::-webkit-scrollbar-thumb {
  background: #121212;
  border-radius: 5px;
}

.setup::-webkit-scrollbar-thumb:hover {
  background: black;
}

.i-Village,
.i-Blue,
.i-Resistance {
  color: #66adff !important;
}

.i-Mafia,
.i-Spies {
  color: #505d66 !important;
}

.i-Cult,
.i-Werewolves {
  color: #b161d3 !important;
}

.i-Independent {
  color: #c7ce48 !important;
}

.i-Red {
  color: #ce4848 !important;
}

.small-role-list {
  display: flex;
  flex-flow: row wrap;

  padding: 5px 0px;
  border-top: 1px solid #d6d6d6;
}

.small-role-list:first-child {
  border-top: none;
}

.small-role-list .role-count-wrap {
  margin-right: 5px;
}

.game-icon {
  flex-shrink: 0;

  width: 30px;
  height: 30px;
}

.game-icon.Mafia {
  background-image: url("/images/game_icons/Mafia.png");
}

.game-icon.Resistance {
  background-image: url("/images/game_icons/Resistance.png");
}

.game-icon.Ghost {
  background-image: url("/images/game_icons/Ghost.png");
}

.game-icon.Jotto {
  background-image: url("/images/game_icons/Jotto.png");
}

.game-icon.Secret-Dictator {
  background-image: url("/images/game_icons/SecretDictator.png");
}

.game-icon.Acrotopia {
  background-image: url("/images/game_icons/Acrotopia.png");
}

.game-icon.Wacky-Words {
  background-image: url("/images/game_icons/WackyWords.png");
}

.game-icon.Liars-Dice {
  background-image: url("/images/game_icons/LiarsDice.png");
}
