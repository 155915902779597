:root {
  --theme-color: #3b3b3b;
  --theme-color-sec: #535353;
  --theme-color-third: #2a2640;
  --theme-color-light: #68a9dc;
  --theme-color-text: #97bde1;
  --scroll-color: #8b8b8b;
  --gradient-color-light: #76bd5b;
  --gradient-color-dark: #385b2b;

  --primaryFont: RobotoSlab;
  --hoverTransition: 0.2s;
  --game-row-odd: #21232a;
  --game-row: #41434d;
  --game-row-odd-h: #2e313b;
  --game-row-h: #4f525e;
}

* {
  /*-webkit-transform: translateZ(0)*/
  /*-webkit-transform-style: preserve-3d;*/
  /*transform-style: preserve-3d;*/
  /*-webkit-backface-visibility: hidden;*/
  /*backface-visibility: hidden;*/
}

.light-mode {
  --scheme-color: #fff;
  --scheme-color-sec: #f3f3f3;
  --scheme-color-text: black;
  --scheme-color-border: #c7c7c7;
  --scheme-color-code: #e0e0e0;
  --gradient-color: var(--gradient-color-light);
}

.dark-mode {
  --scheme-color: #181a1b;
  --scheme-color-sec: #1f2123;
  --scheme-color-text: #ffffff;
  --scheme-color-border: #404548;
  --scheme-color-code: #3d3d3d;
  --gradient-color: var(--gradient-color-dark);
}

@media (prefers-color-scheme: light) {
  html:not(.dark-mode) {
    --scheme-color: #ffffff;
    --scheme-color-sec: #f3f3f3;
    --scheme-color-text: #000000;
    --scheme-color-border: #c7c7c7;
    --scheme-color-code: #e0e0e0;
    --gradient-color: var(--gradient-color-light);
  }
}
@media (prefers-color-scheme: dark) {
  html:not(.light-mode) {
    --scheme-color: rgb(36, 36, 36);
    --scheme-color-sec: rgb(46, 46, 50);
    --scheme-color-text: #ffffff;
    --scheme-color-border: #404548;
    --scheme-color-code: #3d3d3d;
    --gradient-color: var(--gradient-color-dark);
  }
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

  overflow-x: initial;
  background-color: #151514;
  background-image: url("/images/white-diamond-dark.png");
  /* background-size: cover;
  background-repeat: no-repeat; */
  color: white;
  font-size: 16px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

.site-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  /* min-height: 100%; */
}

.main-container {
  display: flex;
  flex-flow: column nowrap;

  /*border: 2px solid;*/
  /*border-image: linear-gradient(#564301, #745b01) 10 100%;*/
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  padding: 20px;
  /* margin-top: 30px; */
}

.header {
  flex-shrink: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 16px;
}

.header .logo-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.header .logo {
  width: 179px;
  height: 105px;
  margin-bottom: 10px;
  background-size: 100% 100%;
}

.header .default-logo {
  background-image: url("/images/fadelogohat.png");
}

.header .pride-logo {
  background-image: url("/images/holiday/umpride2.png");
}

.header .bloody-logo {
  background-image: url("/images/holiday/logobloody.png");
}

.nav-wrapper {
  flex-grow: 1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.inner-container {
  display: flex;
  flex-flow: column;
}

.inner-content {
  display: flex;
  flex-flow: column;
}

.span-panel {
  display: flex;
  flex-flow: column;

  padding: 5px;
}

.span-panel .title {
  margin-bottom: 15px;

  font-weight: 700;
}

.span-panel.main {
  background-color: var(--theme-color);
}

.box-panel {
  display: flex;
  flex-flow: column;

  padding: 8px;

  background-color: var(--scheme-color);
  color: var(--scheme-color-text);
}

.box-panel .heading {
  margin-bottom: 8px;
}

.box-panel .content {
  display: flex;
  flex-flow: column;
}

.box-panel .heading {
  font-weight: 700;
}

.loading-page {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 300px;

  background-color: var(--theme-color);
  color: white;
}

div.center {
  text-align: center;
}

.paragraph {
  margin-bottom: 7px;
  padding: 3px 5px;
  border-left: 3px solid #62a0db;

  background-color: var(--scheme-color);
  color: var(--scheme-color-text);
}

.paragraph:last-child {
  margin-bottom: 0px;
}

.column {
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
}

.dim {
  margin: 0px 5px;
  opacity: 0.7;
}

.item-list {
  display: flex;
  flex-flow: column nowrap;
}

.dropdown-control i:first-child {
  margin-right: 5px;
}

.dropdown-control i:last-child {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 0px;
  left: 0px;

  min-width: 100px;
  padding: 5px 0px;

  background-color: var(--scheme-color);
  color: var(--scheme-color-text);
  box-shadow: 0px 0px 3px black;
  z-index: 5;

  visibility: hidden;
}

.dropdown-menu-option {
  padding: 5px 10px;

  cursor: pointer;
}

.dropdown-menu-option:hover {
  opacity: 0.8;
}

.popover {
  color: var(--scheme-color-text);
}

.popover .role-desc {
  padding-left: 25px;
}

.search-nav {
  display: flex;
  flex-flow: row wrap;
}

.search-wrapper .input-group {
  width: 241px;
}

.form-wrapper {
  margin-top: 10px;
  display: flex;
}

.form-group {
  margin-bottom: 10px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

div:focus,
input:focus {
  outline: none;
}

.btn:focus {
  box-shadow: none;
}

.btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 5px;
  border: none;

  background-color: #6c757d;
  color: white;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: var(--hoverTransition);
}

.btn:hover {
  opacity: 0.8;
}

.btn[disabled] {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.btn-theme {
  background-color: var(--theme-color);
}

.btn-theme-sec {
  background-color: var(--theme-color-sec);
}

.btn-theme-third {
  background-color: var(--theme-color-third);
}

.popover hr {
  margin: 5px 0px;
}

.panel-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.panel-grid .box-panel {
  margin: 0px;
}

.super {
  align-self: flex-start;
}

.none-yet {
  margin-top: 10px;
}

.footer {
  text-align: center;
}

.footer-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
}

.footer-inner a {
  margin-left: 5px;
  margin-right: 5px;
}

::selection,
::-moz-selection {
  background-color: green;
}

.rainbow {
  background: red;
  background: -webkit-linear-gradient(
    left,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  background: -o-linear-gradient(
    right,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  background: -moz-linear-gradient(
    right,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  background: linear-gradient(
    to right,
    orange,
    yellow,
    green,
    cyan,
    blue,
    violet
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-highlight {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default !important;
}

.italic {
  font-style: italic;
}

.notif-bound {
  position: relative;
}

.notif-bound .notif-icon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 100;
  top: -4px;
  right: -4px;

  color: red;
}

.notif-bound .notif-icon.l-offset {
  top: -10px;
  right: -10px;
}

.notif-bound .notif-count {
  position: absolute;

  color: white;

  font-family: Arial;
  font-size: 12px;
  font-weight: 900;
}

.nav .user-wrapper {
  display: flex;
  /* flex-flow: row nowrap; */
  align-items: center;
  justify-content: flex-start;
}

.nav .notifs-wrapper {
  position: relative;
}

.nav .notifs-wrapper .notif-bound:hover {
  opacity: 0.8;
}

.nav .notifs-wrapper i:hover {
  opacity: 1;
}

.nav .notif-list {
  position: absolute;
  top: 30px;
  left: 0px;

  width: 300px;
  max-height: 350px;
  padding: 10px;

  z-index: 10;
  background-color: var(--scheme-color);
  color: var(--scheme-color-text);
  box-shadow: 0px 0px 5px black;
  overflow: visible;
  visibility: hidden;
}

.nav .notif-list .notif {
  display: flex;
  /* flex-flow: row nowrap; */
  align-items: center;
  justify-content: flex-start;

  margin: 0px 0px 10px 0px;
  padding: 0px;
  border: none;

  opacity: 1;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  color: var(--scheme-color-text);
}

.nav .notif-list .notif:hover {
  opacity: 0.7;
}

.nav .notif-list .notif:last-child {
  margin: 0px;
}

.nav .notif-list .notif i {
  margin: 0px 10px 0px 0px;
}

.nav .notif-list .notif .info {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.nav .notif-list .notif .info .time {
  opacity: 0.7;
}

/*
 * Popovers
 */
.popover-body > ul > li > div {
  margin-bottom: 10px;
}

.pop-player {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes delayedShow {
  to {
    visibility: visible;
  }
}

/*
 * Media Queries
 */

@media (max-width: 700px) {
  .header {
    flex-flow: column;
    margin-top: 20px;
  }

  .logo-wrapper {
    margin-left: 0px;
  }

  .nav-wrapper {
    /* flex-flow: column wrap; */
    justify-content: center;
    /* overflow: auto; */
  }

  .nav {
    margin: 10px 0px 10px 0px;
    flex-flow: column nowrap;
  }

  /* .nav .user-wrapper {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
  } */

  a.glow-on-hover {
    margin: 0px;
  }

  .search-bar {
    margin-top: 10px;
  }

  .setup {
    max-width: 300px;
  }

  .panel-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/*
* Custom Fonts
*/

@font-face {
  font-family: JuiceITC;
  src: url("/fonts/juiceitc.ttf");
}

@font-face {
  font-family: Spooky;
  src: url("/fonts/spooky.ttf");
}

@font-face {
  font-family: Autophobia;
  src: url("/fonts/autophobia.ttf");
}

@font-face {
  font-family: Nabla;
  src: url("/fonts/Nabla-Regular-VariableFont_EDPT,EHLT.ttf");
}

@font-face {
  font-family: RobotoSlab;
  src: url("/fonts/RobotoSlab-VariableFont_wght.ttf");
}

.glow-on-hover {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.mui-popover {
  font-family: RobotoSlab;
}

.mui-popover-title {
  display: flex;
  font-size: 24px;
  border-bottom: 2px solid var(--theme-color);
  padding: 4px 4px;
}

.mui-popover-text {
  font-size: 0.875rem; /* 14px */
}

@media only screen and (min-width: 700px) {
  /* For Webkit browsers like Chrome, Safari, and new Edge */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #888;
  }

  *::-webkit-scrollbar-thumb {
    background: #555;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #444;
  }

  /*!* Standard CSS scrollbar styling *!*/
  /** {*/
  /*    scrollbar-width: thin;*/
  /*    scrollbar-color: #555 #888;*/
  /*}*/
}
