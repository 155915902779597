.demoGame {
  flex-grow: 1;
  height: 0;
  overflow: auto;
  padding-bottom: 10px;
}

.highlightMessage {
  width: 100%;
  animation: 8s highlightMessageAnimation;
  animation-fill-mode: forwards;
}

@keyframes highlightMessageAnimation {
  0%,
  50% {
    background: none;
  }
  100% {
    background: rgba(184, 12, 9, 0.25);
  }
}

@media only screen and (min-width: 700px) {
  /* For Webkit browsers like Chrome, Safari, and new Edge */
  .demoGame::-webkit-scrollbar {
    width: 8px;
  }

  .demoGame::-webkit-scrollbar-track {
    background: #888;
  }

  .demoGame::-webkit-scrollbar-thumb {
    background: #555;
  }

  .demoGame::-webkit-scrollbar-thumb:hover {
    background: #444;
  }

  /*!* Standard CSS scrollbar styling *!*/
  /** {*/
  /*    scrollbar-width: thin;*/
  /*    scrollbar-color: #555 #888;*/
  /*}*/
}
