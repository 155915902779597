.modal-bg {
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  z-index: 10;

  background-color: black;
  opacity: 0.7;
}

.modal {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;

  position: fixed;

  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 11;

  margin: auto;
  margin-top: 20px;
  width: 500px;
  border-radius: 30px;
}

.modal-header,
.modal-content,
.modal-footer {
  padding: 10px;
}

.modal-header,
.modal-footer {
  background-color: var(--theme-color-sec);
}

.modal-header {
  border-bottom: 1px solid #1a1a1a;
  border-radius: 3px 3px 0px 0px;
}

.modal-footer {
  border-top: 1px solid #1a1a1a;
  border-radius: 0px 0px 3px 3px;
}

.modal-content {
  background-color: var(--theme-color);
}

.modal a {
  cursor: pointer;
}
