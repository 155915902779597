.refreshButton {
  cursor: pointer;
}

.refreshButton:hover::before {
  animation: animationRefreshButtonHover 2s linear infinite alternate;
}
@keyframes animationRefreshButtonHover {
  0%,
  100% {
    filter: drop-shadow(0 0 4px #ff073a); /* Brighter red */
  }
  14.2% {
    filter: drop-shadow(0 0 4px #ff7e00); /* Neon orange */
  }
  28.5% {
    filter: drop-shadow(0 0 4px #ffeb3b); /* Bright yellow */
  }
  42.8% {
    filter: drop-shadow(0 0 4px #00e676); /* Bright green */
  }
  57.1% {
    filter: drop-shadow(0 0 4px #00b0ff); /* Sky blue */
  }
  71.4% {
    filter: drop-shadow(0 0 4px #651fff); /* Vivid indigo */
  }
  85.7% {
    filter: drop-shadow(0 0 4px #d500f9); /* Bright violet */
  }
}
