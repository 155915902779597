.emote {
  display: inline-block;
  vertical-align: text-top;

  width: 21px;
  height: 21px;
  margin-right: 5px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.emote-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  gap: 1em;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.emote-list .emote {
  margin-left: 4px;
}

.emote-list li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border: 1px solid white;
}

@media screen and (max-width: 780px) {
  .emote-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .emote-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 420px) {
  .emote-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.span-panel h1 {
  margin-left: 20px;
}
