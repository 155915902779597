:root {
  --playItemlistRows: 7;
  --hostItemlistRows: 7;
  --rowHeight: 40px;

  --playItemlistHeight: calc(var(--playItemlistRows) * var(--rowHeight));
  --hostItemlistHeight: calc(var(--hostItemlistRows) * var(--rowHeight));
}

.host > .item-list {
  min-height: var(--hostItemlistHeight);
}

.right-panel > .item-list {
  min-height: var(--playItemlistHeight);
}

.play .item-list {
  background-color: var(--scheme-color);
  color: var(--scheme-color-text);
}

.play .bot-bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  font-family: var(--primaryFont);
  font-weight: bold;
}

.play .top-link {
  width: 120px;
  margin-right: 30px;
  padding: 3px 5px 3px 5px;

  text-align: center;
  cursor: pointer;
  transition: var(--hoverTransition);
}

.top-link:hover {
  background: var(--theme-color-sec);
}

.play .top-link.active {
  background-color: var(--theme-color-sec);
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--scheme-color);
  height: var(--rowHeight);
  padding: 8px;
}
.row.odd {
  background-color: var(--scheme-color-sec);
}

.row .btn {
  padding: 0;
  display: flex;
  align-content: center;

  font-family: var(--primaryFont);
  font-weight: bold;
  font-size: 17px;
}

.refresh-games {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}

.refresh-games:hover {
  color: #d9d9d9;
}

.refresh-games:active {
  color: #a09e9e;
}

.btn-group-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media (max-width: 800px) {
  .play .bot-bar {
    flex-flow: row wrap;
  }

  .play .top-link {
    width: auto;
    margin-right: 3px !important;
    font-size: 15px !important;
  }
}
